import React, {ReactElement} from "react";
import FrameButton from "../buttons/FrameButton";
import {connect, ConnectedProps} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {logout} from "../../redux/auth/authActions";
import classNames from "classnames";
import {useLocation} from "react-router-dom";
import {FaDiscord, FaFacebook, FaInstagram, FaLinkedinIn, FaTwitter} from "react-icons/fa";
import {MdManageAccounts, MdOutlineStorefront} from "react-icons/md";
import {toggleSidebar} from "../../redux/meta/metaActions";
import {useNavigate} from "react-router-dom";
import {Token} from "@devour/client";
import {FiLogIn, FiLogOut} from "react-icons/fi";
import {BiBuildingHouse} from "react-icons/bi";
import {GrMap} from "react-icons/gr";

interface StateProps {
	fullToken: Token;
}

function DevourSidebar(props: DevourSidebarProps): ReactElement {

	const location = useLocation();
	const navigate = useNavigate()

	function logoutHelper(): void {
		toggleSidebarHelper();
		props.dispatch(logout());
		navigate("/");
	}

	function toggleSidebarHelper(): void {
		props.dispatch(toggleSidebar(false));
	}

	return (
		<div className="devour-sidebar">
			<div className="devour-sidebar_top">
				<div className="devour-sidebar_top_branding">
					<img
						src={process.env.PUBLIC_URL + "/images/DevourGO_Final-01.png"}
						alt="DevourGO Logo"
					/>
				</div>

				<div className="devour-sidebar_top_navigation">
					{props.fullToken ? (
						<React.Fragment>
							<FrameButton
								to="/account"
								color="ghost"
								size="normal"
								className={classNames("devour-sidebar_top_navigation_button", {
									"devour-sidebar_top_navigation_button_selected": location.pathname.includes("/account"),
								})}
								leftIcon={MdManageAccounts}
								onClick={toggleSidebarHelper}
							>
								Account
							</FrameButton>

							<FrameButton
								to="/businesses/create"
								color="ghost"
								size="normal"
								className={classNames("devour-sidebar_top_navigation_button", {
									"devour-sidebar_top_navigation_button_selected": location.pathname === "/businesses/create",
								})}
								leftIcon={MdOutlineStorefront}
								onClick={toggleSidebarHelper}
							>
								Create Business
							</FrameButton>

							<FrameButton
								to="/businesses"
								color="ghost"
								size="normal"
								className={classNames("devour-sidebar_top_navigation_button", {
									"devour-sidebar_top_navigation_button_selected":
										location.pathname.includes("/businesses") && !location.pathname.includes("/create"),
								})}
								leftIcon={BiBuildingHouse}
								onClick={toggleSidebarHelper}
							>
								Manage Businesses
							</FrameButton>

							<FrameButton
								to="/brands"
								color="ghost"
								size="normal"
								className={classNames("devour-sidebar_top_navigation_button", {
									"devour-sidebar_top_navigation_button_selected": location.pathname.includes("/brands"),
								})}
								leftIcon={GrMap}
								onClick={toggleSidebarHelper}
							>
								Brands & Maps
							</FrameButton>

							<FrameButton
								color="ghost"
								size="normal"
								className="devour-sidebar_top_navigation_button"
								leftIcon={FiLogOut}
								onClick={logoutHelper}
							>
								Log Out
							</FrameButton>
						</React.Fragment>
					) : (
						<React.Fragment>
							<FrameButton
								to="/"
								color="ghost"
								size="normal"
								className={classNames("devour-sidebar_top_navigation_button", {
									"devour-sidebar_top_navigation_button_selected": location.pathname.includes("/withdraw"),
								})}
								leftIcon={FiLogIn}
								onClick={toggleSidebarHelper}
							>
								Log In
							</FrameButton>
						</React.Fragment>
					)}
				</div>
			</div>

			<div className="devour-sidebar_bottom">
				<div className="devour-sidebar_bottom_socials">
					<FrameButton
						color="gray"
						size="icon-square"
						leftIcon={FaTwitter}
						href="https://twitter.com/GoDevour"
						className="devour-sidebar_bottom_socials_btn"
					/>

					<FrameButton
						color="gray"
						size="icon-square"
						leftIcon={FaInstagram}
						href="https://www.instagram.com/go_devour/"
						className="devour-sidebar_bottom_socials_btn"
					/>

					<FrameButton
						color="gray"
						size="icon-square"
						leftIcon={FaFacebook}
						href="https://www.facebook.com/GoDevour"
						className="devour-sidebar_bottom_socials_btn"
					/>

					{/*<FrameButton*/}
					{/*	color="gray"*/}
					{/*	size="icon-square"*/}
					{/*	leftIcon={FaLinkedinIn}*/}
					{/*	href="https://www.linkedin.com/company/godevour/"*/}
					{/*	className="devour-sidebar_bottom_socials_btn"*/}
					{/*/>*/}

					<FrameButton
						color="gray"
						size="icon-square"
						leftIcon={FaDiscord}
						href="https://discord.com/invite/rfvvuDnPG5"
						className="devour-sidebar_bottom_socials_btn"
					/>
				</div>
			</div>
		</div>
	);
}

function connector() {
	return connect((store: IStore): StateProps => {
		return {
			fullToken: store.authStore.fullToken,
		}
	});
}

type DevourSidebarProps = ConnectedProps<ReturnType<typeof connector>>;

export default connector()(DevourSidebar);
