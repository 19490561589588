"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWGameToJSON = exports.OWGameFromJSONTyped = exports.OWGameFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OWGameFromJSON(json) {
    return OWGameFromJSONTyped(json, false);
}
exports.OWGameFromJSON = OWGameFromJSON;
function OWGameFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gameId': json['gameId'],
        'name': json['name'],
        'description': json['description'],
        'events': !(0, runtime_1.exists)(json, 'events') ? undefined : (json['events'].map(_1.OWGameEventTypeFromJSON)),
        'logo': !(0, runtime_1.exists)(json, 'logo') ? undefined : json['logo'],
        'devourGameLogo': !(0, runtime_1.exists)(json, 'devourGameLogo') ? undefined : json['devourGameLogo'],
        'bannerImage': !(0, runtime_1.exists)(json, 'bannerImage') ? undefined : (0, _1.OWGameImageFromJSON)(json['bannerImage']),
        'landingImage': !(0, runtime_1.exists)(json, 'landingImage') ? undefined : (0, _1.OWGameImageFromJSON)(json['landingImage']),
        'thumbnailImage': !(0, runtime_1.exists)(json, 'thumbnailImage') ? undefined : json['thumbnailImage'],
        'timeRewardImage': !(0, runtime_1.exists)(json, 'timeRewardImage') ? undefined : json['timeRewardImage'],
        'isEventSupported': json['isEventSupported'],
        'isEventSupportReady': !(0, runtime_1.exists)(json, 'isEventSupportReady') ? undefined : json['isEventSupportReady'],
        'userStats': !(0, runtime_1.exists)(json, 'userStats') ? undefined : (json['userStats'].map(_1.OWUserStatFromJSON)),
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}
exports.OWGameFromJSONTyped = OWGameFromJSONTyped;
function OWGameToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'gameId': value.gameId,
        'name': value.name,
        'description': value.description,
        'events': value.events === undefined ? undefined : (value.events.map(_1.OWGameEventTypeToJSON)),
        'logo': value.logo,
        'devourGameLogo': value.devourGameLogo,
        'bannerImage': (0, _1.OWGameImageToJSON)(value.bannerImage),
        'landingImage': (0, _1.OWGameImageToJSON)(value.landingImage),
        'thumbnailImage': value.thumbnailImage,
        'timeRewardImage': value.timeRewardImage,
        'isEventSupported': value.isEventSupported,
        'isEventSupportReady': value.isEventSupportReady,
        'userStats': value.userStats === undefined ? undefined : (value.userStats.map(_1.OWUserStatToJSON)),
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}
exports.OWGameToJSON = OWGameToJSON;
