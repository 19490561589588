"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWGameImageToJSON = exports.OWGameImageFromJSONTyped = exports.OWGameImageFromJSON = void 0;
var runtime_1 = require("../runtime");
function OWGameImageFromJSON(json) {
    return OWGameImageFromJSONTyped(json, false);
}
exports.OWGameImageFromJSON = OWGameImageFromJSON;
function OWGameImageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'small': !(0, runtime_1.exists)(json, 'small') ? undefined : json['small'],
        'large': !(0, runtime_1.exists)(json, 'large') ? undefined : json['large'],
    };
}
exports.OWGameImageFromJSONTyped = OWGameImageFromJSONTyped;
function OWGameImageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'small': value.small,
        'large': value.large,
    };
}
exports.OWGameImageToJSON = OWGameImageToJSON;
