"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWUserStatToJSON = exports.OWUserStatFromJSONTyped = exports.OWUserStatFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OWUserStatFromJSON(json) {
    return OWUserStatFromJSONTyped(json, false);
}
exports.OWUserStatFromJSON = OWUserStatFromJSON;
function OWUserStatFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': json['userId'],
        'gameId': !(0, runtime_1.exists)(json, 'gameId') ? undefined : json['gameId'],
        'game': !(0, runtime_1.exists)(json, 'game') ? undefined : (0, _1.OWGameFromJSON)(json['game']),
        'isClaimed': !(0, runtime_1.exists)(json, 'isClaimed') ? undefined : json['isClaimed'],
        'reward': (0, _1.OWRewardFromJSON)(json['reward']),
        'rewardCategory': (0, _1.OWLogCategoryFromJSON)(json['rewardCategory']),
        'progress': json['progress'],
        'target': json['target'],
        'weekStart': !(0, runtime_1.exists)(json, 'weekStart') ? undefined : json['weekStart'],
        'weeklyProgress': !(0, runtime_1.exists)(json, 'weeklyProgress') ? undefined : json['weeklyProgress'],
        'isWeeklyLimitFeedbackDismissed': !(0, runtime_1.exists)(json, 'isWeeklyLimitFeedbackDismissed') ? undefined : json['isWeeklyLimitFeedbackDismissed'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
    };
}
exports.OWUserStatFromJSONTyped = OWUserStatFromJSONTyped;
function OWUserStatToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'gameId': value.gameId,
        'game': (0, _1.OWGameToJSON)(value.game),
        'isClaimed': value.isClaimed,
        'reward': (0, _1.OWRewardToJSON)(value.reward),
        'rewardCategory': (0, _1.OWLogCategoryToJSON)(value.rewardCategory),
        'progress': value.progress,
        'target': value.target,
        'weekStart': value.weekStart,
        'weeklyProgress': value.weeklyProgress,
        'isWeeklyLimitFeedbackDismissed': value.isWeeklyLimitFeedbackDismissed,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
    };
}
exports.OWUserStatToJSON = OWUserStatToJSON;
